import { Route } from "react-router-dom";
import { getLocaleKeys } from "../i18n/locales";
import { DetailPage } from "./detail-page";
import { ItemList } from '../consts/item-list';

export const DetailPageRoutes = (): JSX.Element[] => {

    const DetailPageRoutes: JSX.Element[] = [];
    ItemList.forEach(itemName => {
        getLocaleKeys().forEach(localeKey => {
            const path = `${localeKey}/${itemName}`;
            DetailPageRoutes.push(<Route path={path} key={path} element={<DetailPage language={localeKey} itemName={itemName} />} ></Route>);
        });
        const defaultPath = itemName;
        DetailPageRoutes.push(<Route path={defaultPath} key={defaultPath} element={<DetailPage language={"en"} itemName={itemName} />} ></Route>);
    })


    DetailPageRoutes.push(<Route path="/" key="root" element={<DetailPage language='en' itemName="all"/>} ></Route>);
    return DetailPageRoutes;
}