import { Menu, Row, Col, Dropdown, Button, Space } from 'antd';
import './header.css';
import '../i18n/i18n';
import { baseURL } from '../consts/const';
import { codeMap, getLocaleKeys } from '../i18n/locales';
import i18n from '../i18n/i18n';
import { useState } from 'react';
import { changeLanguage, t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const localeKeys = getLocaleKeys();

const makeLanguageItems = (navigate: (path: string) => void) => (pathname: string) => {
    return localeKeys.map((locale: string) => {
        const pathSplit = pathname.split('/');
        const pathIncludeLocaleKey = localeKeys.includes(pathSplit[1]);
        if (pathIncludeLocaleKey) {
            pathSplit.splice(1, 1, locale)
        } else {
            pathSplit.splice(1, 0, locale)
        }
        return {
            key: locale,
            label: codeMap[locale],
            onClick: () => { navigate(pathSplit.join('/')); changeLanguage(locale); }
        };
    })
  };

export const Header = () => {
    const [selectedItem, setSelectedItem] = useState<string>("home");
    const navigate = useNavigate();
    const location = useLocation();

    const langMenu = <Menu items={makeLanguageItems(navigate)(location.pathname)}/>;

    const menuClick = (e: { key: string; }) => {
        setSelectedItem(e.key);
      };

    return <header className="App-header">
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={4} className="logo-column">
          <div>
            <a id='logo' href={`${baseURL}`}>
              <img alt="logo" src={`${baseURL}/logo192.png`} style={
                { height: 32, width: 32 }
                } />
              <img alt="name" src={`${baseURL}/name.svg`} style={
                { height: 14, marginLeft: 5, width: 128 }
                } />
            </a>
          </div>
        </Col>
        <Col xs={0} sm={0} md={14} lg={16} xl={16} xxl={18}>
          <Menu selectedKeys={[selectedItem]} onClick={menuClick} mode="horizontal" theme='light' style={
            { background: '#F9FCFF' }
            }>
            <Menu.Item key="home">
              <a href={baseURL} rel="noopener noreferrer">{t('home')}</a>
            </Menu.Item>
            <Menu.Item key="contact">
              <a href="mailto:support@allprintabledoc.com" rel="noopener noreferrer">{t('contactus')}</a>
            </Menu.Item>
            <Menu.Item key="faq">
              <a href={`${baseURL}/faq`}>{t('footer.FAQ')}</a>
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2}>
          <Dropdown overlay={langMenu} placement="bottomRight">
            <Button style={
              { background: 'none', border: 'none', boxShadow: 'none' }
              }>
              <Space>
                <img alt="language" src={`${baseURL}/earth.svg`} style={
                  { height: 16, width: 16, display: 'flex' }
                  } />
                {codeMap[i18n.language]}
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </header>;
  }