import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';
import { HomePageHead } from './homepage-head';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';

export const HomePage = (props: {language?: string}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage(props.language || 'en');
    }, []);
  
  
    return (
      <div className="App">
          <HomePageHead language={props.language || 'en'} />
          <Header />
          <div className='container'>

          </div>
          <Footer />
      </div>
    );
}