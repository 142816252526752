const ItemList = [
    "monthly-budget-worksheet",
    "travel-checklist",
    "wedding-invitations",
    "course-schedule",
    "maps-and-navigation-guides",
    "restaurant-menus",
    "study-note-templates",
    "sports-event-schedule",
    "fitness-plan-worksheet",
    "job-application-form",
    "passport-application-form",
    "interview-question-guide",
    "new-employee-onboarding-manual",
    "freshman-orientation-guide",
    "study-goal-planner",
    "vacation-itinerary-planner",
    "healthy-eating-plan",
    "daily-weekly-monthly-journal",
    "team-building-activity-planner",
    "attendance-sheet",
    "project-progress-report",
    "faq-guide",
    "class-timetable",
    "meeting-agenda",
    "business-travel-itinerary",
    "phone-directory",
    "family-budget-planner",
    "school-homework-assignment-sheet",
    "goal-setting-and-tracking-sheet",
    "meeting-minutes",
    "snack-recipes",
    "family-gathering-invitations",
    "student-certificate-templates",
    "academic-paper-writing-guide",
    "product-sales-brochure",
    "research-report-template",
    "travel-journal",
    "mental-health-diary",
    "mood-tracking-sheet",
    "study-planner",
    "grocery-shopping-list",
    "resume-template",
    "family-task-assignment-sheet",
    "interview-evaluation-form",
    "meeting-invitations",
    "school-exam-timetable",
    "career-development-plan",
    "travel-budget-worksheet",
    "social-media-content-calendar",
    "marketing-brochure",
    "study-group-discussion-record",
    "product-user-manual",
    "project-task-checklist",
    "family-health-record-sheet",
    "survey-template",
    "repair-service-quote-form",
    "student-classroom-performance-evaluation-form",
    "academic-paper-outline",
    "personal-growth-plan-sheet",
    "travel-itinerary-schedule",
    "community-event-flyer",
    "product-specification-sheet",
    "attendance-record-sheet",
    "hotel-reservation-confirmation-form",
    "contact-form",
    "social-media-content-calendar",
    "catering-order-form",
    "project-plan-sheet",
    "household-chores-assignment-sheet",
    "student-scholarship-application-form",
    "meeting-room-reservation-form",
    "travel-expense-reimbursement-form",
    "car-maintenance-record-sheet",
    "health-medical-record-form",
    "academic-speech-template",
    "consultancy-service-contract",
    "nutrition-and-diet-guide",
    "team-feedback-collection-form",
    "house-for-sale-advertisement",
    "course-evaluation-questionnaire",
    "product-feedback-survey-form",
    "electronic-device-warranty-form",
    "project-risk-management-form",
    "meeting-discussion-record",
    "vacation-leave-application-form",
    "professional-skills-assessment-form",
    "family-safety-plan-sheet",
    "competition-registration-form",
    "organizational-chart",
    "product-sales-report",
    "publication-subscription-form",
    "course-evaluation-report",
    "accommodation-booking-confirmation-form",
    "home-renovation-plan-sheet",
    "press-release-template",
    "event-planning-sheet",
    "student-scholarship-evaluation-form",
    "baby-feeding-log",
    "market-research-report",
    "python-cheat-sheet",
    "react-cheat-sheet",
    "javascript-cheat-sheet",
    "html-cheat-sheet",
    "css-cheat-sheet",
    "sql-cheat-sheet",
    "java-cheat-sheet",
    "c-cheat-sheet",
    "c++-cheat-sheet",
    "php-cheat-sheet",
    "ruby-cheat-sheet",
    "swift-cheat-sheet",
    "kotlin-cheat-sheet",
    "go-cheat-sheet",
    "r-cheat-sheet",
    "typescript-cheat-sheet",
    "scala-cheat-sheet",
    "excel-cheat-sheet",
    "git-cheat-sheet",
    "linux-cheat-sheet",
    "docker-cheat-sheet",
    "aws-cheat-sheet",
    "azure-cheat-sheet",
    "color-theory-cheat-sheet",
    "algebra-cheat-sheet",
    "linear-algebra-cheat-sheet",
    "calculus-cheat-sheet",
    "geometry-cheat-sheet",
    "trigonometry-cheat-sheet",
    "statistics-cheat-sheet",
    "probability-cheat-sheet",
    "physics-cheat-sheet",
    "chemistry-cheat-sheet",
    "biology-cheat-sheet",
    "anatomy-cheat-sheet",
    "geology-cheat-sheet",
    "meteorology-cheat-sheet",
    "psychology-cheat-sheet",
    "sociology-cheat-sheet",
    "economics-cheat-sheet",
    "accounting-cheat-sheet",
    "finance-cheat-sheet",
    "marketing-cheat-sheet",
    "management-cheat-sheet",
    "business-cheat-sheet",
    "machine-learning-cheat-sheet",
    "deep-learning-cheat-sheet",
    "data-science-cheat-sheet",
    "ai-cheat-sheet",
    "windows-shortcuts-cheat-sheet",
    "mac-shortcuts-cheat-sheet",
    "astronomy-cheat-sheet",
];

module.exports = { ItemList };