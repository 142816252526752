export const codeMap: {[i18nCode: string]: string} = {
    "en": "English",
    "zh": "中文",
    "de": "Deutsch",
    "es": "Español",
    "fr": "Français",
    "ru": "Русский", 
    "ja": "日本語",
    "ko": "한국어",
    "it": "Italiano",
    "pt": "Português",
    "id": "Bahasa Indonesia",
};

export const getLocaleKeys = (): string[] => {
    return Object.keys(codeMap);
}
