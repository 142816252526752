import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';
import { DetailPageHead } from './detail-page-head';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';

export const DetailPage = (props: {language?: string, itemName: string}) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
      i18n.changeLanguage(props.language || 'en');
    }, []);
  
  
    return (
      <div className="App">
          <DetailPageHead language={props.language || 'en'} itemName={props.itemName} />
          <Header />
          <div className='container'>
            <h1>{t(`items.${props.itemName}.title`)}</h1>
            <h2>{t(`items.${props.itemName}.description`)}</h2>
            <p>{t(`items.${props.itemName}.long-description`)}</p>
          </div>
          <Footer />
      </div>
    );
}