import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../i18n/i18n';
import { getLocaleKeys } from '../i18n/locales';
import { baseURL } from '../consts/const';

export const DetailPageHead = (props: { language?: string, itemName: string }) => {
    const { t, i18n } = useTranslation();
    return (
        <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t(`items.${props.itemName}.title`)}</title>
            <meta name="description" content={t(`items.${props.itemName}.description`)} />
            <meta name="keywords" content={t(`items.${props.itemName}.keywords`)} />
            {
                getLocaleKeys().map(key => {
                    const url = `${baseURL}/${key}/${props.itemName}`;
                    return <link rel="alternate" hrefLang={key} href={url} key={url} />;
                })
            }
            {
                <link rel="alternate" hrefLang="x-default" href={`${baseURL}/en/${props.itemName}`} key="xdefault" />
            }
            {
                <link rel="canonical" href={`${baseURL}/en/${props.itemName}`} key="canonical" />
            }
        </Helmet>
    );
}