import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../i18n/i18n';
import { getLocaleKeys } from '../i18n/locales';
import { baseURL } from '../consts/const';

export const HomePageHead = (props: {language?: string}) => {
    const { t, i18n } = useTranslation();
    return  (
        <Helmet htmlAttributes={{ lang: props.language }}>
            <meta charSet="utf-8" />
            <title>{t('headHomepage.title')}</title>
            <meta name="description" content={t('headHomepage.description')} />
            <meta name="keywords" content={t('headHomepage.keywords')} />
            {
                getLocaleKeys().map(key => <link rel="alternate" hrefLang={key} href={`${baseURL}/${key}`} key={`${baseURL}/${key}`} />)
            }
            {

                <link rel="alternate" hrefLang="x-default" href={baseURL} key="xdefault" />
            }
            {
                <link rel="canonical" href={baseURL} key="canonical" />
            }
        </Helmet>
        );
}