import { BrowserRouter, Routes } from "react-router-dom";
import { useMemo } from "react";
import 'antd/dist/antd.css';
import './App.css';
import { HomePageRoutes } from "./components/homepage/routes";
import { DetailPageRoutes } from "./components/detail-page/routes";
import { FaqRoutes } from "./components/faq/routes";
import { TOSRoutes } from "./components/tos/routes";

const App = () => {

  const homePageRoutes = useMemo(() => HomePageRoutes(), [])
  const detailPageRoutes = useMemo(() => DetailPageRoutes(), [])
  const faqRoutes = useMemo(() => FaqRoutes(), [])
  const tosRoutes = useMemo(() => TOSRoutes(), [])

  return (
    <BrowserRouter>
      <Routes>
        {homePageRoutes}
        {detailPageRoutes}
        {faqRoutes}
        {tosRoutes}
      </Routes>
    </BrowserRouter>
  )
}

export default App;
