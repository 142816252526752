import { Route } from "react-router-dom";
import { getLocaleKeys } from "../i18n/locales";
import { HomePage } from "./homepage";

export const HomePageRoutes = (): JSX.Element[] => {

    const homePageRoutes: JSX.Element[] = [];

    getLocaleKeys().forEach(key => {
        homePageRoutes.push(<Route path={`${key}`} key={`${key}`} element={<HomePage language={key} />} ></Route>);
    });

    homePageRoutes.push(<Route path="/" key="root" element={<HomePage language='en' />} ></Route>);
    return homePageRoutes;
}