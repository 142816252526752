import React, { useEffect } from 'react';
import './faq.css';
import '../../App.css';
import { Collapse } from 'antd';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { FAQHead } from './faq-head';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

export const FAQ = (props: { language?: string }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.language || 'en');
  }, []);


  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <div className="App">
      <FAQHead language={props.language || 'en'} />
      <Header />
      <div className='container'>
          <Collapse defaultActiveKey={['1','2','3','4']} onChange={onChange}>
              <Panel header={t('FAQ.question1.question')} key="1">
                  <p>{t('FAQ.question1.answer')}</p>
              </Panel>
              <Panel header={t('FAQ.question2.question')} key="2">
                  <p>{t('FAQ.question2.answer')}</p>
              </Panel>
              <Panel header={t('FAQ.question3.question')} key="3">
                  <p>{t('FAQ.question3.answer')}</p>
              </Panel>
              <Panel header={t('FAQ.question4.question')} key="4">
                  <p>{t('FAQ.question4.answer')}</p>
              </Panel>
          </Collapse>
      </div>
      <Footer />
    </div>
  );
};
